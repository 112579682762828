<template>
  <section id="login" class="vh-100 bg-bgPrimary d-flex align-items-center justify-content-center">
    <b-card class="overflow-hidden" style="min-height: 303px">
      <b-card-title class="text-center">
        <h3 class="text-uppercase">Evipstudio CRM</h3></b-card-title
      >
      <b-card-body>
        <!--  LOGIN FORM BEGIN -->
        <b-form
          class="login-signup animate__fadeIn animate__animated"
          v-if="state !== 'signup' && state !== 'forgot'"
          @submit.prevent="onLogin"
        >
          <b-form-group>
            <b-form-input
              class="my-1"
              id="login_page-login"
              placeholder="Login"
              v-model="$v.form.username.$model"
              :state="validateState('username')"
              aria-describedby="formUsernameFeedback"
            />
            <b-form-invalid-feedback id="formUsernameFeedback">
              Niepoprawna wartość
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group>
            <b-form-input
              class="my-1"
              id="login_page-password"
              type="password"
              :placeholder="$t('PASSWORD')"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="formPasswordFeedback"
            />
            <b-form-invalid-feedback id="formPasswordFeedback">
              Hasło jest wymagane
            </b-form-invalid-feedback>
          </b-form-group>
          <b-button
            type="submit"
            class="mt-3 w-100 text-white"
            :disabled="$v.form.$invalid"
            variant="success"
            >{{ $t("LOGIN") }}</b-button
          >
          <b-button
            class="mt-3 w-100 text-center font-weight-bold"
            variant="transparent"
            size="sm"
            @click="state = 'forgot'"
            >{{ $t("FORGOT_PASSWORD") }}</b-button
          >
        </b-form>
        <!--  LOGIN FORM END -->

        <!-- FORGOT PASSWORD BEGIN -->
        <b-form
          class="login-forgot animate__slideInRight animate__animated"
          v-if="state !== 'signup' && state !== 'signin'"
          @submit.prevent="onRequestPassword"
        >
          <b-form-group>
            <b-form-input
              required
              class="my-1"
              id="login_page-login"
              placeholder="E-mail"
              v-model="$v.requestPassword.email.$model"
              :state="$v.requestPassword.$dirty ? !$v.requestPassword.$error : null"
            />
          </b-form-group>
          <b-button
            type="submit"
            :disabled="$v.requestPassword.$invalid"
            class="w-100 text-center mt-3 text-white"
            variant="success"
            >{{ $t("FORGOT_PASSWORD") }}</b-button
          >
          <b-button
            class="w-100 text-center mt-3 font-weight-bold"
            variant="transparent"
            size="sm"
            @click="state = 'signin'"
            >{{ $t("LOGIN") }}</b-button
          >
        </b-form>
        <!-- FORGOT PASSWORD END-->
      </b-card-body>
    </b-card>
    <div class="copyright">Wszelkie prawa zastrzeżone. Powered by Evipstudio.pl</div>
  </section>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import { email } from "vuelidate/lib/validators";
import { LOGIN, REQUEST_PASSWORD } from "@/core/store/auth.module";

export default {
  name: "Login",
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
      requestPassword: {
        email: null,
      },
      state: "signin",
      forgotPassword: false,
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onLogin() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$store
        .dispatch(LOGIN, this.form)
        .then(() => {
          this.$bvToast.toast("Login success", {
            title: "Login success",
            variant: "success",
            autoHideDelay: 3000,
            appendToast: true,
          });
          this.$router.push({ name: "dashboard" });
        })
        .catch(({ data }) => {
          this.$bvToast.toast(`${data.message}`, {
            title: "Login failed",
            variant: "danger",
            appendToast: true,
          });
        });
    },
    onRequestPassword() {
      this.$store
        .dispatch(REQUEST_PASSWORD, this.requestPassword.email)
        .then(() => {
          this.$bvToast.toast("Success", {
            title: "Request password success",
            variant: "success",
            appendToast: true,
          });
        })
        .catch(({ data }) => {
          this.$bvToast.toast(`${data.message}`, {
            title: "Request password failed",
            variant: "danger",
            appendToast: true,
          });
        });
    },
  },
  validations: {
    form: {
      username: {
        required,
        email,
      },
      password: {
        required,
      },
    },
    requestPassword: {
      email: {
        required,
        email,
      },
    },
  },
  mounted() {
    if (this.$route.query.resetPassword) this.state = "forgot";
  },
};
</script>

<style lang="scss">
.copyright {
  position: absolute;
  bottom: 10px;
  text-align: center;
  padding-top: 35px;
  color: #a6a6a6;
  font-size: 14px;
  width: 100%;
}

.login-card {
  background-color: #fd9400;
}
.fade-in {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* The style below is just for the appearance of the example div */
</style>
